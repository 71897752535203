.section {
    height: 100vh;
    width: 100vw;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.grpah {
    display: flex;
    align-items: flex-end;
    column-gap: 20px;
}

.info {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    row-gap: 20px;
    border: 1px solid black;
    padding: 10px;
    background-color: #fff;
}

.info__blockWr {
    display: flex;
    column-gap: 20px;
    width: 100%;
}

.info__block {
    height: 20px;
    width: 50px;
    min-width: 50px;
}

.info__block_darkBlue {
    background-color: #5661ff;
}

.info__block_blue {
    background-color: #9ce1f4;
}

.info__block_gray {
    background-color: #CCDFE4;
}

.info__block_green {
    background-color: #00ff44;
}

.info__block_red {
    background-color: #fea494;
}

.info__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    background-color: white;
}

.wrapper {
    position: relative;
    height: 700px;
    margin-bottom: 20px;
}
.wrapper_full {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.reactFlow {
    background: transparent
}

.block {
    position: relative;
    padding: 10px;
    width: 360px;
    height: 120px;
    border-radius: 5px;
    border: 1px solid #909090;
    background-color: #fff;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.block_parent {
    border-top: 10px solid #9ce1f4;
}

.block_parent_empty {
    border-top: 10px solid #5661ff;
}

.block_target {
    border-top: 10px solid #CCDFE4;
}

.block_child {
    border-top: 10px solid #00ff44;
}

.block_childEmpty {
    border-top: 10px solid #fea494;
}

.block__shortname {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
    margin-right: 10px;
}

.block__unp {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.block__selectBtn {
    height: 20px;
    width: 20px;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0px;
}

.block__selectBtn:hover {
    background-color: #dedede;
    border-radius: 50%;
    cursor: pointer;
}

.block__popup {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.block__popupCloseBtn {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block__popupCloseBtn:hover {
    background-color: #dedede;
    border-radius: 50%;
    cursor: pointer;
}

.block__popupBtn {
    height: calc(50% - 10px);
    width: 100%;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #000;
}

.block__popupBtn:hover {
    background-color: #eaf0ff;
    cursor: pointer;
}

.block__nameWr {
    position: relative;
    margin-bottom: 5px;
}
.download__btn {
    background-color: white;
    color: green;
    border: 1px solid green;
    padding: 10px;
    cursor: pointer;
}
.download__btn:hover {
    background-color: green;
    color: white;
    padding: 10px;
    cursor: pointer;
}
.legend__btn {
    background-color: white;
    color: blueviolet;
    border: 1px solid blueviolet;
    padding: 10px;
    cursor: pointer;
}
.legend__btn:hover {
    background-color: blueviolet;
    color: white;
    padding: 10px;
    cursor: pointer;
}
.reset__btn {
    background-color: white;
    color: red;
    border: 1px solid red;
    padding: 10px;
    cursor: pointer;
}
.reset__btn:hover {
    background-color: red;
    color: white;
    padding: 10px;
    cursor: pointer;
}
.section__closeButton {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;

}

.section__closeButton:hover {
    background-color: rgb(225, 225, 225);
}
.btns_wr {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.containerRoutes {
    margin-bottom: 20px;
}