.section {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.sort__container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.modalBtn__block {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}
.filterTable__container {
    display: flex;
    column-gap: 20px;
    max-width: 1300px !important;
}

.cont {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
    border-radius: 4px;
    max-height: fit-content;
    height: fit-content;
}
.table__container {
    max-width: 1300px !important;
}

.table__container::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
}

.table__container::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;

}

.table__container::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
    cursor: pointer;
}

.table {
    max-width: 1300px !important;
    overflow-x: auto !important;
}

.table__head {
    background: rgba(30, 110, 190, 0.1);
}

.table__head b {
    color: #1E6EBE;
}
.footer {
    display: flex;
    align-items: center;
    column-gap: 106px;
    padding: 20px;
}

.footer__btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 14px;
}

.footer__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 39px;
    background: #1e6ebe;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    cursor: pointer;
}


.cell__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.link {
    position: relative;
    font-weight: 700;
    font-size: 13px;
    color: #030DFF;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.containerModalRow {
    position: absolute;
    left: 80px;
    top: -30px;
    width: 185px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: 5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.containerModalRow a {
    flex: 1;
    font-weight: 400;
    line-height: 120%;
    color: #333333;
    padding: 0 20px;
    display: flex;
    height: 30px;
    align-items: center;
    font-size: 13px;
}

.containerModalRow a:hover {
    background-color: #F5F9FA;
}

.cell__wrapper_horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.organizationsFieldsModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.organizationsFieldsModal .form {
    position: relative;
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    outline: none;
    height: 92%;
    border-radius: 4px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form__header {
    height: 8%;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
}

.form__main {
    overflow-y: auto;
    padding-left: 16px;
}

.form__main::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.form__main::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

.form__main::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.form__sectionsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.form__section {
    display: flex;
    flex-direction: column;
}

.form__sectionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(51, 51, 51, 0.7);
}

.form__sectionContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 32px;
}

.form__chackboxBlock {
    pointer-events: none;
}

.form__toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333333;
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
}

.form__toggler>* {
    padding: 0 !important;
}

.form__checkboxLabel {
    margin: 10px;
    font-size: 16px;
}

.form__checkbox_unchecked {
    opacity: 0;
}

.form__footer {
    padding: 16px;
}

.form__saveBtn {
    height: 39px;
    width: 200px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
}

.form__saveBtn:hover {
    cursor: pointer;
}
.cell_checkbox {
    width: 40px !important;
}

.cell_ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    height: 30px;
}